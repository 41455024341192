/**
 * ================
 * Utilities
 * ================
 */

body #page-container {
  .only-desktop {
    @media (max-width: 767px) {
      display: none;
    }
  }

  .only-mobile {
    display: none;
    @media (max-width: 767px) {
      display: block;
    }
  }
}

/**
 * ================
 * Block :: Scroll Fix
 * ================
 */

.scroll-fix {
  position: relative;
  overflow: hidden;

  padding-top: 200px;
  padding-right: 0;

  background-color: #fff;

  z-index: 100;

  @media (max-width: 767px) {
    display: none;
  }

  .section-floating-wrapper {
    align-items: flex-start;
    z-index: 99;

    .section-floating-link {
      transform: rotate(-90deg) translate(-80vh, -90px);
    }
  }

  .scroll-list-items {
    margin: 0;
    padding: 75px 0;

    color: $dark;
    text-align: center;

    list-style: none;
    counter-reset: services-counter;
  }

  .scroll-item {
    width: 100%;
    min-height: 75vh;
    margin: 0 auto;
    padding: 0 3vw;
    text-align: left;

    counter-increment: services-counter;

    &:nth-child(even) {
      &:before {
        color: $colorAccent3;
      }
    }
    &:nth-child(odd) {
      &:before {
        color: $colorAccent2;
      }
    }

    &:before {
      content: "0"counter(services-counter);
      font-weight: 100;
      line-height: 3;
      font-size: 16px;

      display: none;
    }

    hr {
      margin: 25px auto;
      padding: 0;
      border: none;
      height: 1px;
      width: 60%;
      background: rgb(25 99 61 / 15%);
    }
  }

  .scroll-item-title {
    color: $colorAccent2;
    font-size: 3.75rem;
    margin-top: 20px;
    text-align: left;

    &.smaller {
      font-size: 2.75rem;
    }
  }

  .title, .title.medium {
    font-weight: 500;
    line-height: 1.45;
  }

  .scroll-item-subtitle {
    color: $colorAccent3;
    font-size: 1.675rem;
    margin-top: 20px;
    text-align: left;

    &.smaller {
      font-size: 1.05rem;
    }
  }

  .scroll-item-text {
    color: #2C7751;
    font-size: 16px;
    max-width: 300px;
    margin: 0 auto;
    text-align: left;
  }

  .split-cta {
    color: $dark;
    margin-top: 25px;

    span {
      color: $dark;
    }
  }
}

.scroll-grid {
  position: relative;
  z-index: 10;

  display: flex;
  align-items: stretch;
  justify-content: space-between;

  .col-left,
  .col-right {
    width: 50%;
  }

  .col-left {
    position: relative;
    z-index: 99;
    padding: 0 5vw 0 calc(110px + 4vw);

    @media (max-width: 1000px) {
      padding: 0 5vw 0 30px;
    }

    @media (max-width: 767px) {
      padding: 0 5vw 0 15px;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: -100%;
      right: 0;
      bottom: -100%;
      left: -100%;

      z-index: 1;

      background: #fbe27d;
    }

    * {
      position: relative;
      z-index: 2;
    }

    img.icon-img {
      width: 80px;
      display: inline-block;
      margin-bottom: 20px;
    }

    .title {
      color: $colorAccent1;

      &.big {
        font-size: 60px;
      }
    }

    .regular-text {
      color: $colorAccent1;
    }
  }

  .col-right {
    .mask {
      display: none;

      position: absolute;
      top: -100%;
      right: -100%;
      bottom: 0;
      left: 50%;

      z-index: 10;

      background: #fff;

      transform: none;
      transition: all 1.15s cubic-bezier(0.87, 0, 0.13, 1) 0.45s;
    }
  }
}

.right-side-img {
  position: relative;
  top: -3.5rem;
  margin-top: -200px;
  padding-left: 0;

  .floating-title {
    position: absolute;
    top: 0;
    right: 20px;
    z-index: 10;

    padding: 10px;
    max-width: 275px;
    text-align: center;
    background: rgba($dark, 0.93);
    border: 1px solid rgba(#fff, 0.25);
    color: #fff;

    font-size: 0.875rem;
    line-height: 1.1;
    font-weight: 200;
    border-radius: 4px;
  }

  .img-wrapper-1 {
    position: relative;
    width: 100%;
    height: 100vh;

    .credits {
      position: absolute;
      right: 0;
      top: 55px;
      background-color: rgba(0,0,0,0.4);
      padding: 7px 10px;
      z-index: 9999;
      bottom: auto;

      p {
        color: #fff;
        font-size: 12px;
      }
    }

    .img-wrap {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 120vh;
      object-fit: cover;

      opacity: 0;
      filter: none;
      transform: scale(1.25);
      transition: all 0.6s cubic-bezier(0.87, 0, 0.13, 1) 0s;
    }

    &.is-pos-1 {
      .img-wrap:nth-child(1) {
        opacity: 1;
        filter: none;
        transform: none;
      }
    }

    &.is-pos-2 {
      .img-wrap:nth-child(2) {
        opacity: 1;
        filter: none;
        transform: none;
      }
    }

    &.is-pos-3 {
      .img-wrap:nth-child(3) {
        opacity: 1;
        filter: none;
        transform: none;
      }
    }

    &.is-pos-4 {
      .img-wrap:nth-child(4) {
        opacity: 1;
        filter: none;
        transform: none;
      }
    }

    &.is-pos-5 {
      .img-wrap:nth-child(5) {
        opacity: 1;
        filter: none;
        transform: none;
      }
    }

    &.is-pos-6 {
      .img-wrap:nth-child(6) {
        opacity: 1;
        filter: none;
        transform: none;
      }
    }

    &.is-pos-7 {
      .img-wrap:nth-child(7) {
        opacity: 1;
        filter: none;
        transform: none;
      }
    }

    &.is-pos-8 {
      .img-wrap:nth-child(8) {
        opacity: 1;
        filter: none;
        transform: none;
      }
    }
  }
}

#page-container .scroll-fix.is-active {
  .col-right .mask {
    transform: translateX(100%);
  }
}

.scroll-fix-mobile {
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
}

.scroll-fix-mobile {
  position: relative;
  overflow: hidden;
  padding: 0;
  margin-top: 45px;

  .max-container {
    padding: 0;
  }

  .scroll-grid {
    flex-wrap: wrap;
  }
  .scroll-grid .col-left,
  .scroll-grid .col-right {
    width: 100%;
  }

  .scroll-grid .col-left {
    margin: 0;
    padding: 0;
  }

  .scroll-list-items {
    margin: 0;
    padding: 0 0;

    color: $dark;
    text-align: center;

    list-style: none;
    counter-reset: services-counter;
  }

  .scroll-item {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 45px;

    counter-increment: services-counter;

    &:nth-child(even) {
      &:before {
        color: $colorAccent3;
      }
    }
    &:nth-child(odd) {
      &:before {
        color: $colorAccent2;
      }
    }

    &:before {
      content: "0"counter(services-counter);
      font-weight: 100;
      line-height: 3;
      font-size: 16px;

      display: none;
    }

    hr {
      margin: 10px auto;
      padding: 0;
      border: none;
      height: 1px;
      width: 60%;
      background: rgb(25 99 61 / 15%);
    }
  }

  img {
    display: block;
    width: 100%;
    max-height: 380px;
    object-fit: cover;
    margin-bottom: 35px;
  }

  .scroll-item-title {
    color: $colorAccent3;
    font-size: 2.275rem;
    margin-top: 10px;

    &.smaller {
      font-size: 1.75rem;
    }
  }

  .scroll-item-subtitle {
    color: $colorAccent3;
    font-size: 1.375rem;
    margin-top: 15px;

    &.smaller {
      font-size: 1.05rem;
    }
  }

  .scroll-item-text {
    color: #2C7751;
    font-size: 15px;
    max-width: 300px;
    margin: 0 auto;
  }

  img.icon-img {
    width: 80px;
    display: inline-block;
    margin-bottom: 20px;
  }

  .title {
    color: $color2;

    &.big {
      font-size: 60px;
    }
  }

  .regular-text {
    color: #fff;
  }
}


/**
 * ================
 * Block :: Charts
 * ================
 */
.charts-row {
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  padding: 25px 0;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }

  .charts-col {
    width: 100%;
    padding: 0 20px;

    @media (max-width: 767px) {
      margin: 0;
      padding: 0;
    }

    canvas {
      margin: 0 auto;
    }

    &.is-2 {
      width: 50%;
    }

    &.is-3 {
      width: calc(100% / 3);

      @media (max-width: 767px) {
        width: 100%;
      }
    }

    &.is-4 {
      width: calc(100% / 4);

      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
}

/**
 * Extends GLightbox
 */
.gnext,
.gprev {
  display: none !important;
}
.extra-content {
  display: none;

  .site-btn {
    display: block;
    max-width: 175px;
    margin: 15px auto;
    border: 2px solid #52a163;
    border-radius: 50px;
    color: #52a163;
    font-size: 14px;
    text-align: center;
    padding: 10px 20px;
    background: #fff;
    font-weight: 100;
    font-family: 'Source Sans Pro', sans-serif;

    transition: all 0.5s ease 0s;

    &:hover {
      color: #fff;
      background: #52a163;
    }
  }

  &.ginlined-content {
    display: block;
  }

  figure, img {
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
  }

  strong,
  p strong {
    color: $colorAccent1;
    text-transform: uppercase;
  }
}

.extra-content-title {
  color: $colorAccent1;
}

.extra-content-text {
  color: #eee;
}

.img.diagram{
  padding: 5vw;
}