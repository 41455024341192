

$color1: #1b4638;
$color2: #fbe27d;
$color3: #f9f7f2;
$color4: #323639;

$colorAccent1: #1b4638;
$colorAccent2: #fbe27d;
$colorAccent3: #f9f7f2;
$colorAccent4: #323639;

$dark: #1b4638;
$white: #fff;





.title {
	font-family: 'Raleway', sans-serif;
	font-weight: 700;
	line-height: 1.2;

	&.medium {
		font-size: 25px;
		margin-bottom: 30px;
		color: $colorAccent1;
		font-weight: 500;
		line-height: 1.75;
		padding-right: 4vw;
	}

	&.small {
		font-size: 18px;
		margin-bottom: 0;
		color: $colorAccent1;
	}

	@media screen and (max-width: 1000px) {
		&.medium {
			font-size: 20px;
		}
	}
}

.regular-text {
	font-family: 'Raleway', sans-serif;
	font-weight: 300;
	line-height: 1.34;
	font-size: 16px;
	color: $colorAccent1;

	@media screen and (max-width: 1000px) {
		font-size: 14px;
	}
}

ul.regular-text {
	list-style-type: disc;
	padding-left: 35px;
}