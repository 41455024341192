body {
	&.active {
		overflow-x: hidden;
	}
}

.main-wrap {
	width: 100%;
	transition: all 0.4s ease;
	background-color: $colorAccent3;

	&.active {
		margin-left: 350px;
	}

	@media screen and (max-width: 1000px) {
		margin-left: 0;
		width: 100%;
	}
}

.spacing {
	padding: 7vw 4vw 7vw calc(110px + 4vw);

	@media screen and (max-width: 1000px) {
		padding: 7vw 4vw 7vw 4vw;
	}
}

.no-top-spacing {
	padding-top: 0;
}

.main-title {
	width: 100%;
	padding: 7vw 0 4vw calc(110px + 4vw);

	.title-wrap {
		position: relative;
	}

	.title {
		color: $color1;
		text-transform: uppercase;
		font-size: 35px;
		background-color: $colorAccent3;
		padding-right: 50px;
		position: relative;
		z-index: 100;
		display: inline-block;

		&.force-medium {
			font-size: 25px;
			text-transform: none;
			margin-bottom: 2vw;
			font-weight: 500;
		}
	}

	.line {
		width: 100%;
		height: 1px;
		background-color: $color1;
		position: absolute;
		top: 15px;
		right: 0;
		z-index: 1;
	}

	.regular-text {
		margin-top: 5vw;
		max-width: 850px;
		margin-bottom: 3vw;
	}

	@media screen and (max-width: 1000px) {
		padding: 10vw 15px 6vw 15px;

		.title {
			font-size: 24px;
		}
	}
}

.main-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 110px;
	height: 100vh;
	border-right: 1px solid rgba(0,0,0,0.1);
	z-index: 1000;
	transition: all 0.4s ease;

	.close {
		width: 40px;
		position: absolute;
		top: 50%;
		right: -20px;
		transform: translate(0,-50%) scale(0.5,0.5);
		cursor: pointer;
		transition: all 0.4s ease;
		opacity: 0;
	}

	.lang {
		position: absolute;
		right: 60px;
		bottom: 20px;
		color: #323639;
		opacity: 0;
		transition: all 0.4s ease;
	}

	.main-nav {
		position: absolute;
		top: 50%;
		left: -400px;
		transform: translate(0,-50%);
		opacity: 0;
		transition: all 0.6s ease;

		a {
			width: 100%;
			color: #323639;
			display: block;
			font-size: 15px;
			margin-bottom: 20px;
			transition: all 0.2s ease;

			&:hover {
				color: $color2;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.logo {
		position: absolute;
		left: 50%;
		top: 20px;
		transform: translate(-50%,0);
		transition: all 0.4s ease;
	}

	.style {
		text-align: center;
		cursor: pointer;

		img, svg {
			width: 100%;
			margin-bottom: 10px;
			display: inline-block;
		}

		p {
			font-family: 'Montserrat', sans-serif;
			font-weight: 700;
			color: #323639;
			font-size: 15px;
			display: inline-block;
		}
	}

	.menu {
		width: 50px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		opacity: 1;
		transition: all 0.4s ease;
	}

	.don {
		width: 50px;
		position: absolute;
		left: 50%;
		bottom: 20px;
		transform: translate(-50%,0);
		transition: all 0.4s ease;

		svg {
			stroke: none !important;
		}
	}

	@media screen and (max-width: 1000px) {
		display: none;
	}

	.logo {
		transition: all 0.4s ease;

		path {
			transition: all 0.4s ease;
		}

		tspan, text {
			transition: all 0.4s ease;
		}
	}

	.style, .don p, .menu p {
		transition: all 0.4s ease;
	}

	&[data-nav-theme="intro"] {
		border-right: 0;
		
		.logo {
			fill: #fff;
			width: 150px;
			top: 30px;
			left: 100px;

			fill: #fff;
			width: 250px;
			top: -25px;
			left: 110px;

			&.logo-en {
				width: 150px;
				top: 30px;
				left: 100px;
			}

			path {
				fill: #fff;
			}

			tspan, text {
				fill: #fff;
				color: #fff;
			}
		}

		.style, .don p, .menu p {
			stroke: #fff;
			fill: #fff;
			color: #fff;
		}

		.main-nav a {
			&:hover {
				color: $colorAccent1;
			}
		}
	}

	&[data-nav-theme="transparent"],
	&[data-nav-theme="green"] {
		.logo {
			fill: #fff;
			width: 80px;

			path {
				fill: #fff;
			}

			tspan, text {
				fill: #fff;
				color: #fff;
			}
		}

		.style, .don p, .menu p {
			stroke: #fff;
			fill: #fff;
			color: #fff;
		}

		.main-nav a {
			&:hover {
				color: $colorAccent1;
			}
		}
	}

	&[data-nav-theme="yellow"] {
		background: $colorAccent2;

		.logo {
			fill: $colorAccent1;
			width: 80px;

			path {
				fill: $colorAccent1;
			}

			tspan, text {
				fill: $colorAccent1;
				color: $colorAccent1;
			}
		}

		.style, .don p, .menu p {
			stroke: $colorAccent1;
			fill: $colorAccent1;
			color: $colorAccent1;
		}

		.main-nav a {
			&:hover {
				color: $colorAccent1;
			}
		}
	}

	&[data-nav-theme="white"] {
		background: $colorAccent3;

		.logo {
			fill: #323639;
			width: 80px;

			path {
				fill: #323639;
			}

			tspan, text {
				fill: #323639;
				color: #323639;
			}
		}

		.style, .don p, .menu p {
			stroke: #323639;
			fill: #323639;
			color: #323639;
		}

		.main-nav a {
			&:hover {
				color: $colorAccent1;
			}
		}
	}

	&.active {
		width: 350px;

		.logo {
			width: 150px;

			&.logo-en {
				width: 150px;
			}
		}

		.menu {
			opacity: 0;
		}

		.don {
			left: 30px;
			transform: translate(0,0);
		}

		.lang {
			right: 30px;
			opacity: 1;
		}

		.main-nav {
			left: 30px;
			opacity: 1;
		}

		.close {
			transform: translate(0,-50%) scale(1,1);
			opacity: 1;
		}

		.logo {
			fill: #323639;

			path {
				fill: #323639;
			}

			tspan, text {
				fill: #323639;
				color: #323639;
			}
		}

		.style, .don p, .menu p {
			stroke: #323639;
			fill: #323639;
			color: #323639;
		}
	}
}

.mobile-header {
	display: none;
	width: 100%;
	height: 63px;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
	padding: 10px 20px;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100000;
	background-color: #fff;
	transition: all 0.2s ease;
	overflow: hidden;

	&.open {
		height: 100%;
		.mobile-burger {
			display: none;
		}
		.close-menu {
			display: block;
		}
		.logo {
			width: 150px;

			&.logo-en {
				width: 150px;
			}
		}
		.main-nav {
			opacity: 1;
			top: 0;
		}
		.bottom {
			.don {
				opacity: 1;
				top: 0;
			}
			.lang {
				opacity: 1;
				top: 0;
			}
		}
	}

	.logo {
		width: 50px;
		transition: all 0.4s ease;

		&.logo-en {
			width: 50px;
		}
	}

	.main-nav {
		width: 100%;
		opacity: 0;
		transition: all 0.5s ease;
		transition-delay: 0.2s;
		position: relative;
		top: 20px;

		a {
			color: #323639;
			display: block;
			margin-bottom: 10px;
		}
	}

	.bottom {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		.don {
			text-align: center;
			cursor: pointer;
			width: 50px;
			opacity: 0;
			transition: all 0.5s ease;
			transition-delay: 0.4s;
			position: relative;
			top: 20px;
			img {
				width: 100%;
				margin-bottom: 10px;
				display: inline-block;
			}

			p {
				font-family: 'Montserrat', sans-serif;
				font-weight: 700;
				color: #323639;
				font-size: 15px;
				text-transform: uppercase;
				display: inline-block;
			}
		}

		.lang {
			color: #323639;
			opacity: 0;
			transition: all 0.5s ease;
			transition-delay: 0.6s;
			position: relative;
			top: 20px;
		}
	}

	.mobile-burger {
		background-color: transparent;
		border:0;
		cursor: pointer;

		img {
			width: 30px;
			margin-top: 8px;
		}
	}

	.close-menu {
		background-color: transparent;
		border:0;
		cursor: pointer;
		display: none;

		img {
			width: 30px;
			margin-top: 8px;
		}
	}

	@media screen and (max-width: 1000px) {
		display: flex;
	}
}

.hero {
	position: relative;
	width: 100%;
	height: 100vh;
	background-image: url('/img/2022/hero.jpg');
	background-position: center center;
	background-size: cover;

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.3);
		z-index: 1;
	}

	.options {
		display: flex;
		align-items: center;
		position: absolute;
		top: 20px;
		right: 30px;
		z-index: 100;

		.title {
			color: #fff;
			font-size: 15px;
			margin-left: 10px;
		}

		.share {
			display: flex;
			align-items: center;
			margin-left: 15px;

			.share-icon {
				width: 15px;
				margin-left: 10px;
			}


			.title {
				margin: 0;
				padding: 0;
			}
		}
	}

	.data {
		position: absolute;
		top: 50%;
		left: calc(110px + 4vw);
		transform: translate(0,-50%);
		z-index: 100;
	}

	.scroll {
		width: 60px;
		position: absolute;
		right: 30px;
		bottom: 20px;
		z-index: 100;
	}

	.logo {
		fill: #fff;
		width: 200px;
		
		&.logo-en {
			width: 200px;
		}

		path {
			fill: #fff;
		}
	}

	.title.big {
		color: #fff;
		font-size: 5.25vw;
		font-weight: 600;
		margin-bottom: 45px;
	}

	.title.small {
		color: #fff;
		font-size: 1.5vw;
		font-weight: 200;
	}

	@media screen and (max-width: 1000px) {
		height: auto;
		padding: 30vw 5vw;
		margin-top: 60px;
		.options {
			display: none;
		}
		.data {
			position: relative;
			top: 0;
			left: 0;
			transform: translate(0,0);
			z-index: 100;
		}
		.title.big {
			font-size: 5.5vw;
			margin-bottom: 20px;
		}

		.title.small {

			font-size: 16px;
		}
	}

	@media screen and (max-width: 767px) {
		.title.big {
			font-size: 38px;
			margin-bottom: 20px;
		}
	}
}

.bloc-img-txt {
	width: 100%;
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	&.green {
		background: $colorAccent1;
		color: #fff;

		.title.medium {
			color: #fff;
		}

		.regular-text {
			color: #fff;
			span {
				color: #fff;
			}
		}

		ul {
			color: #fff;
			li {
				color: #fff;
			}
		}
	}

	&.left {
		.data {
			padding-right: 5vw;
		}
	}

	&.right {
		.data {
			padding-left: 5vw;
		}
	}

	&.graph {
		.img {
			padding: 0 3vw;
		}
	}

	.data {
		width: 50%;

		&.full {
			width: 100%;
		}

		.italic {

		}

		.regular-text {
			padding-bottom: 20px;
			line-height: 1.35;
			padding-right: 4vw;
			span {
				font-weight: 600;
			}
		}

		ul {
			padding-left: 25px;
			margin: 10px 0 20px 0;
			li {
				list-style: disc;
			}
		}
	}

	.img {
		position: relative;
		width: 50%;
		overflow: hidden;

		&.full {
			width: 100%;
		}

		.credits {
			position: absolute;
			right: 0;
			bottom:0;
			background-color: rgba(0,0,0,0.4);
			padding: 7px 10px;

			p {
				color: #fff;
				font-size: 12px;
			}
		}
	}

	.regular-text.notes {
		font-size: 14px;
		font-weight: 400;
		font-style: italic;
		text-align: center;
	}

	.wrap {
		display: flex;
		align-items: center;
		margin: 20px 0;

		.regular-text {
			font-weight: 600;
			margin-right: 20px;

			&.notes {
				font-size: 14px;
				font-weight: 400;
				font-style: italic;
				text-align: center;
			}
		}
	}

	.presentation {
		font-family: Raleway, sans-serif;
		display: flex;
		align-items: center;
		padding-bottom: 35px;

		img {
			width: 80px;
			margin-right: 20px;
		}

		.regular-text {
			line-height: 1.35;
			padding-bottom: 0;
			span {
				font-weight: 400;
			}
		}
	}

	@media screen and (max-width: 1000px) {
		flex-wrap: wrap;
		.data {
			width: 100%;
			order: 1;
			padding: 0 !important;
		}
		.img {
			width: 100%;
			order:0;
			margin-bottom: 5vw;
		}
	}
}

.saviez-vous {
	width: 100%;
	display: flex;
	align-items: center;

	.data {
		width: 50%;

		a {
			
		}

		span {
			font-weight: 600;
		}
	}

	.img {
		position: relative;
		width: 50%;
		overflow: hidden;
		.credits {
			position: absolute;
			right: 0;
			bottom:0;
			background-color: rgba(0,0,0,0.4);
			padding: 7px 10px;
			p {
				color: #fff;
				font-size: 12px;
			}
		}
		.play {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			width: 50px;
		}
	}

	&.dark {
		background-color: $color1;

		.title {
			color: $color2;
		}

		.regular-text {
			color: #fff;
		}
	}

	&.light {
		background-color: $color3;

		.title {
			color: $color1;
			font-weight: 500;
			font-size: 28px;
		}
	}

	&.right {
		.data {
			padding-right: 5vw;
		}
	}

	&.left {
		.data {
			padding-left: 5vw;
		}
	}

	@media screen and (max-width: 1000px) {
		flex-wrap: wrap;

		.data {
			order: 1;
			width: 100%;
			padding: 0 !important;
		}

		.img {
			order: 0;
			width: 100%;
			margin-bottom: 5vw;
		}
	}
}

.bloc-icon-txt {
	width: 100%;
	display: flex;
	align-items: center;
	padding-right: 0;

	.data {
		width: 50%;
		padding-right: 5vw;
	}

	.icon-wrap {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		width: 50%;
	}

	.icon-wrap.three {
		.icon {
			width: calc(100% / 3);
			
			@media (max-width: 767px) {
				width: calc(100% / 2);
			}
		}
	}

	.icon-wrap.four {
		.icon {
			width: calc(100% / 4);
			
			@media (max-width: 767px) {
				width: calc(100% / 2);
			}
		}
	}

	.icon .icon-img {
		position: relative;
		margin-bottom: 15px;

		svg {
			margin-bottom: 0;
		}

		img {
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			width: 35%;
			height: 35%;
			object-fit: contain;
			height: auto;
			transform: translate(-50%,-50%);
			margin: 0 0 0 -2	px;
			padding: 0;
		}
	}

	.icon {
		width: calc(100% / 2);
		text-align: center;
		margin: 30px 0;
		padding: 0 15px;

		&.small {
			margin: 20px 0;
			img {
				width: 60px;
				margin-bottom: 0;
			}
			.title {
				&.medium {
					margin-bottom: 10px;
				}
			}
		}

		img {
			width: auto;
			height: 80px;
			display: inline-block;
			margin-bottom: 20px;
		}

		img, svg {
			margin-bottom: 15px;
		}

		.regular-text {
			font-size: 14px;
			&.big {
				font-weight: 600;
				font-size: 150%;
			}
		}

		a {
			font-family: 'Montserrat', sans-serif;
			font-weight: 700;
			color: $color1;
			font-size: 44px;
		}
	}

	@media screen and (max-width: 1000px) {
		flex-wrap: wrap;
		padding-right: 5vw;

		.data {
			width: 100%;
			order: 0;
			padding: 0 !important;
			margin-bottom: 5vw;
		}

		.icon {
			width: 50%;
			padding: 0 3vw;

			img {
				width: 60px;
				margin-bottom: 10px;
			}
		}

		.icon-wrap {
			width: 100%;
			order: 1;
		}
	}
}


.right-side-img {
	width: 100%;

	.wrap {
		width: 100%;
		display: flex;
		align-items: center;
		background-color: $color1;

		.data {
			width: 50%;
			padding: 0 5vw 0 calc(110px + 4vw);
			text-align: center;

			img {
				width: 80px;
				display: inline-block;
				margin-bottom: 20px;
			}

			.title {
				color: $color2;

				&.big {
					font-size: 60px;
				}
			}

			.regular-text {
				color: #fff;
			}
		}

		.img {
			position: relative;
			width: 50%;
			overflow: hidden;
			.credits {
				position: absolute;
				right: 0;
				bottom:0;
				background-color: rgba(0,0,0,0.4);
				padding: 7px 10px;
				p {
					color: #fff;
					font-size: 12px;
				}
			}
		}
	}

	@media screen and (max-width: 1000px) {
		.wrap {
			flex-wrap: wrap;

			.data {
				width: 100%;
				order: 1;
				padding: 10vw 5vw;
			}

			.img {
				order: 0;
				width: 100%;
			}
		}
	}
}

.carrousel-wrap {
	width: 100%;
	padding-top: 7vw;

	&.light {
		background-color: $color3;
	}

	&.dark {
		background-color: $color1;

		.title-center {
			.title {
				color: $color2;
			}
		}

		.main-carousel {
			.carousel-cell {
				background-color: #F6F9F7;
				.data {
					background-color: #F6F9F7;

					.title {
						color: $color1;
					}

					.regular-text {
						color: $color4;

						span {
							font-weight: 600;
						}
					}
				}
			}
		}
	}

	.title-center {
		text-align: center;
		max-width: 800px;
		margin: 0 auto;
	}

	.arrows-wrap {
		width: 100%;
		position: relative;

		.arrow-left {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translate(0,-50%);
			width: 50px;
			cursor: pointer;
		}

		.arrow-right {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translate(0,-50%);
			width: 50px;
			cursor: pointer;
		}
	}

	.main-carousel {
		width: 100%;
		margin-top: 5vw;

		.carousel-cell {
			width: 100%;
			display: flex;
			align-items: center;
			background-color: $color1;

			.img {
				position: relative;
				width: 50%;
				overflow: hidden;
				.credits {
					position: absolute;
					right: 0;
					bottom:0;
					background-color: rgba(0,0,0,0.4);
					padding: 7px 10px;
					p {
						color: #fff;
						font-size: 12px;
					}
				}
			}

			.data {
				width: 50%;
				padding: 5vw;

				.title {
					color: $color2;
				}

				.regular-text {
					color: #fff;

					&.bold {
						font-weight: 600;
						color: $color2;
					}

					span {
						font-weight: 600;
					}
				}
			}
		}
	}

	@media screen and (max-width: 1000px) {
		.main-carousel {
			.carousel-cell {
				flex-wrap: wrap;

				.img {
					order: 0;
					width: 100%;
				}

				.data {
					width: 100%;
					order: 1;
					min-height: 350px;
				}
			}
		}
		.arrows-wrap {

			.arrow-left {
				top: 30%;
				transform: translate(0,0);
			}

			.arrow-right {
				top: 30%;
				transform: translate(0,0);
			}
		}
	}

	@media screen and (max-width: 500px) {
		.main-carousel {
			.carousel-cell {
				.data {
					min-height: none;
				}
			}
		}
		.arrows-wrap {

			.arrow-left {
				top: 23%;
				width: 30px;
			}

			.arrow-right {
				top: 23%;
				width: 30px;
			}
		}
	}
}


.video-list {
	width: 100%;

	.videos-wrap {
		display: flex;
		justify-content: space-between;
		margin-top: 3vw;

		.video {
			width: 32%;
			position: relative;
			cursor: pointer;

			.play {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				width: 50px;
			}
		}
	}

	@media screen and (max-width: 1000px) {
		.videos-wrap {
			flex-wrap: wrap;

			.video {
				width: 100%;
				margin-bottom: 5vw;
			}
		}
	}
}

.icons {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;

	&.light {
		background-color: $color3;
	}

	.data {
		width: 100%;
		margin-bottom: 5vw;
		text-align: center;

		.title {
			font-size: 2.75vw;
			text-transform: uppercase;
			letter-spacing: 2.5px;
			color: $colorAccent1;
		}
	}

	.icon-wrap {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		width: 100%;
	}

	.icon {
		width: calc(100% / 5);
		text-align: center;
		margin: 30px 0;
		padding: 0 15px;

		@media (max-width: 767px) {
			width: calc(100% / 2);
		}

		img {
			width: auto;
			height: 80px;
			display: inline-block;
			margin-bottom: 20px;
		}

		.regular-text {
			&.big {
				font-weight: 600;
			}
		}

		a {
			font-family: 'Montserrat', sans-serif;
			font-weight: 700;
			color: $color1;
			font-size: 44px;
		}
	}

	@media screen and (max-width: 860px) {
		justify-content: center;
	}
}


.testimonials {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;

	&.light {
		background-color: $color3;
	}

	.data {
		width: 100%;
		margin-bottom: 5vw;
		text-align: center;
	}

	.card-wrap {
		width: 300px;
		height: 355px;
		perspective: 600px;
		margin-right: 5vw;
		margin-bottom: 5vw;
	}

	.card {
		width: 100%;
		height: 100%;
		transition: transform 1s;
		transform-style: preserve-3d;
		cursor: pointer;
		position: relative;

		&.is-flipped {
			transform: rotateY(180deg);

			.front {
				.flip {
					display: none;
				}
			}
		}

		.front {
			position: absolute;
			width: 100%;
  			height: 100%;
  			-webkit-backface-visibility: hidden;
  			backface-visibility: hidden;

  			.credits {
				position: absolute;
				right: 0;
				bottom:0;
				background-color: rgba(0,0,0,0.4);
				padding: 7px 10px;

				p {
					color: #fff;
					font-size: 12px;
				}
			}

			.flip {
				position: absolute;
				top: 10px;
				right: -25px;
				width: 50px;

				@media (max-width: 767px) {
					right: 0;
				}
			}
		}

		.back {
			position: absolute;
			width: 100%;
  			height: 100%;
  			background-color: $color1;
  			-webkit-backface-visibility: hidden;
  			backface-visibility: hidden;
  			transform: rotateY(180deg);
  			padding: 3vw 1vw 3vw 3vw;

  			.wrap {
  				overflow-y: scroll;
  				padding-bottom: 3vw;
  				height: 290px;
  				padding-right: 2vw;

  			}

  			.quote {
  				width: 30px;
  				margin-bottom: 20px;
  			}

  			.regular-text {
  				color: #fff;
  				font-style: italic;

  				&.normal {
  					font-style: normal;
  				}

  				span {
  					font-weight: 600;
  				}
  			}

			.flip {
				position: absolute;
				top: 10px;
				right: -25px;
				width: 50px;
			}
		}
	}

	@media screen and (max-width: 860px) {
		justify-content: center;

		.card-wrap {
			width: 30vw;
			margin-top: 5vw;
			margin-bottom: 5vw;
		}
	}

	@media screen and (max-width: 860px) {
		.card-wrap {
			width: 100%;
			max-width: 355px;
			height: 400px;
			margin-top: 5vw;
			margin-bottom: 5vw;
			margin-right: 0;
			margin-bottom: 45px;
		}
	}
}


.scroll-slider {
	width: 100%;
	padding-right: 0;
	overflow-x: scroll;

	.scroll-wrap {
		width: fit-content;
		display: flex;
	}

	.slider-cell {
		width: 350px;
		margin-right: 30px;
		cursor: pointer;

		@media (max-width: 499px) {
		  width: 250px;
		  margin-right: 15px;
		}

		&.intro {
			width: 500px;
			padding-right: 5vw;
			padding-top: 4vw;

			@media (max-width: 499px) {
			  width: 300px;
			  padding-right: 2.5vw;
			}

			.title {
				text-align: left;
				font-size: 28px;
				font-weight: 500;
				line-height: 1.45;
				padding-right: 4vw;
			}

			.regular-text {
				text-align: left;
				width: 100%;
				margin: 0;
				padding: 0;
				padding-top: 25px;
				max-width: 100%;

				&.link {
					text-decoration: underline;
				}
			}
		}

		.wrap {
			display: flex;
			align-items: center;
			margin-top: 30px;

			img {
				width: 50px;
				margin-right: 20px;
			}

			.regular-text {
				font-weight: 600;
				color: $color1;
				margin: 0;
				padding: 0;

				&.link {
					text-decoration: underline;
				}
			}
		}

		.regular-text {
			padding-top: 25px;
			text-align: center;
			max-width: 80%;
			margin: 0 auto;
			span {
				font-weight: 500;
			}

			&.link {
				text-decoration: underline;
			}
		}

		.title {
			color: $color1;
			font-size: 34px;
			text-align: center;
		}

		.img {
			position: relative;
			margin-bottom: 10px;
			overflow: hidden;
			.credits {
				position: absolute;
				right: 0;
				bottom:0;
				background-color: rgba(0,0,0,0.4);
				padding: 7px 10px;
				p {
					color: #fff;
					font-size: 12px;
				}
			}
		}
	}

	@media screen and (max-width: 1000px) {
		.slider-cell {
			&.intro {
				width: 350px;
			}
		}
	}

	@media screen and (max-width: 499px) {
		.slider-cell {
			&.intro {
				width: 300px;
			  padding-right: 2.5vw;
			}
		}
	}
}

.icon-stats {
	width: 100%;
	display: flex;
	justify-content: space-between;

	.stat {
		text-align: center;
		.title {
			margin-bottom: 10px;
		}
		img {
			width: auto;
			height: 45px;
			display: inline-block;
			margin-bottom: 10px;
		}
		.regular-text {
			padding: 0 2vw;
			line-height: 1.5;
		}
	}

	@media screen and (max-width: 1000px) {
		flex-wrap: wrap;

		.stat {
			width: 50%;
			margin-bottom: 5vw;
		}
	}
}

.split-text-content {
	width: 100%;
	display: flex;
	justify-content: space-between;

	.data {
		width: 50%;
		padding-right: 5vw;

		&.dark {
			width: 35%;
			padding: 4vw;
			border: 2px solid $color1;

			.title {
				width: 100%;
				color: $color1;
				margin-bottom: 20px;
				font-size: 18px;
			}
		}
	}

	@media screen and (max-width: 1000px) {
		flex-wrap: wrap;

		.data {
			width: 100%;
			margin-bottom: 5vw;
		}
	}
}

.logos {
	width: 100%;
	display: flex;
	flex-wrap: wrap;

	.row {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}

	.title {
		width: 100%;
	}

	.logo {
		width: 24%;
		margin-right: 10px;
		margin-top: 20px;

		&.logo-en {
			width: 24%;
		}

		&.bigger {
			transform: scale(1.325) translateX(20px);
		}
	}

	@media screen and (max-width: 600px) {
		.logo {
			width: 100%;

			&.logo-en {
				width: 100%;
			}

			&.bigger {
				transform: none;
			}
		}
	}
}


.main-footer {
	width: calc(100% - 110px);
	margin-left: 110px;
	background-color: $color1;

	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1vw 5vw;

	.regular-text {
		color: #fff;
	}

	.jnv {
		display: flex;
		align-items: center;

		img {
			width: 40px;
			margin-left: 5px;
		}
	}

	@media screen and (max-width: 1000px) {
		width: 100%;
		margin-left: 0;
		flex-wrap: wrap;
		justify-content: center;
		padding: 5vw;

		.copy {
			width: 100%;
			text-align: center;
			margin-bottom: 3vw;
		}
	}
}

.popup-wrap {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;

	&.show {
		z-index: 10000;

		.content {
			transform: translate(-50%,-50%) scale(1,1);
			opacity: 1;
		}

		.opacity {
			background-color: rgba(0,0,0,0.8);
		}

		.close {
			transform: scale(1,1);
			opacity: 1;
		}
	}

	.content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%) scale(0.8,0.8);
		width: 100%;
		height: fit-content;
		max-width: 70vw;
		max-height: 70vh;
		overflow-y: scroll;
		padding: 5vw;
		background-color: #fff;
		z-index: 100;
		opacity: 0;
		transition: all 0.4s ease;

		.regular-text {
			margin-top: 20px;

			&.italic {
				font-style: italic;
			}

			a {
				color: inherit;
				text-decoration: underline;
			}
		}
	}

	.opacity {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: rgba(0,0,0,0);
		z-index: 10;
		transition: all 0.4s ease;
	}

	.close {
		background-color: transparent;
		border:0;
		cursor: pointer;
		position: absolute;
		top: 30px;
		right: 30px;
		z-index: 100;
		transform: scale(0.5,0.5);
		opacity: 0;
		transition: all 0.4s ease;
	}

	@media screen and (max-width: 1000px) {
		&.show {
			z-index: 99999999999999;
		}
		.content {
			max-width: 800px;
			max-height: 400px;
		}
	}
}

[data-popup-content] {
	display: none;
}

.block-timeline {
	background: $colorAccent1;
	color: $colorAccent2;
}

.timeline-wrap {
	position: relative;
	padding-left: 12vw;
	padding-right: 6vw;
	padding-bottom: 4vw;

	@media (max-width: 1000px) {
		padding-left: 30px;
		padding-right: 30px;
	}

	@media (max-width: 767px) {
		padding-left: 15px;
		padding-right: 15px;
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(3vw, 0);
		margin-left: -6px;
		width: 12px;
		height: 12px;
		background: $colorAccent2;
		border-radius: 50%;
		z-index: 1;

		@media (max-width: 767px) {
			display: none;
		}
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 50%;
		transform: translateX(3vw);
		width: 1px;
		border-left: 1px dashed $colorAccent2;
		z-index: 1;

		@media (max-width: 767px) {
			transform: none;
			opacity: 0.675;
		}
	}

	.timeline-row {
		display: flex;
		align-items: center;
		justify-content: space-between;

		padding-bottom: 4vw;

		&:nth-child(even) {
			flex-direction: row-reverse;

			@media (max-width: 767px) {
				flex-direction: column;
			}

			.timeline-image {
				position: relative;
				padding-right: 0;
				padding-left: 4vw;

				@media (max-width: 767px) {
					padding-left: 0;
					padding-right: 0;
				}
			}

			.timeline-content {
				text-align: right;

				@media (max-width: 767px) {
					text-align: center;
				}
			}

			.timeline-title {
				padding-left: 0;
				padding-right: 45px;

				@media (max-width: 767px) {
					padding-left: 0;
					padding-right: 0;
				}
			}

			.icon {
				position: absolute;
				top: 50%;
				right: 0;
				left: auto;
				transform: translate(50%, -50%);
			}

			li {
				padding-left: 0;
				padding-right: 45px;

				@media (max-width: 767px) {
					padding-left: 0;
					padding-right: 0;
				}

				&:before {
					position: absolute;
					top: 50%;
					right: 0;
					left: auto;
				}
			}
		}

		@media (max-width: 767px) {
			flex-direction: column;
		}

		.timeline-image {
			position: relative;
			width: 50%;
			padding-right: 4vw;
			padding-top: 4vw;
			padding-bottom: 2vw;
			overflow: hidden;
			z-index: 2;

			@media (max-width: 767px) {
				width: 100%;
				padding-right: 0;
				padding-top: 0;
				padding-bottom: 0;
			}

			img {
				display: block;
				width: 100%;
			}
		}

		.timeline-content {
			position: relative;
			z-index: 2;
			width: 50%;
			text-align: left;

			@media (max-width: 767px) {
				width: 100%;
				text-align: center;
			}
		}

		.timeline-title {
			font-size: 28px;
			padding-bottom: 25px;
			padding-left: 45px;

			@media (max-width: 767px) {
				padding-left: 0;
				padding-top: 0;
				padding-bottom: 0;
				margin-top: 25px;
				margin-bottom: 25px;
				text-align: center;
				background: $colorAccent1;
			}
		}

		.regular-text {
			color: $colorAccent2;

			@media (max-width: 767px) {
				background: $colorAccent1;
			}
		}

		.icon {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(-50%, -50%);
			display: block;
			width: 12px;
			height: 12px;
			background: $colorAccent2;
			border-radius: 50%;

			@media (max-width: 767px) {
				display: none;
			}
		}

		li {
			position: relative;
			padding-top: 25px;
			padding-bottom: 25px;
			font-size: 14px;
			font-weight: 200;
			padding-left: 45px;

			position: relative;

			@media (max-width: 767px) {
				padding-left: 0;
				padding-top: 0;
				padding-bottom: 0;
				margin-bottom: 25px;
				text-align: center;
			}
			
			&:before {
				content: '';
				position: absolute;
				top: 50%;
				left: 0;
				width: 5%;
				height: 1px;
				border-top: 1px dashed $colorAccent2;
				z-index: 1;

				@media (max-width: 767px) {
					display: none;
				}
			}

			strong {
				display: block;
				font-weight: 600;
				font-size: 150%;
			}
		}
	}
}

/*     display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

	*/

.fh-slider {
	display: flex;
	align-items: stretch;
	justify-content: space-between;

	@media (max-width: 767px) {
		flex-direction: column-reverse;
		align-items: center;
		justify-content: center;
	}

	&.green {
		.title {
			color: $colorAccent2;
		}

		.regular-text {
			color: $color3;
		}

		* {
			color: $color3;
		}

		.flickity-button {
			background: $colorAccent2;

			svg {
				path {
					fill: $colorAccent4;
				}
			}
		}
	}

	.left {
		width: 50%;
		padding: 6vw 6vw 6vw 12vw;

		@media (max-width: 1000px) {
			padding: 6vw 15px 6vw 30px;
		}

		@media (max-width: 767px) {
			width: 100%;
			padding: 6vw 15px 6vw 15px;
			text-align: center;
		}

		[data-slide-content] {
			display: none;
			height: 100%;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;

			&.is-active {
				display: flex;
			}
		}
	}

	.right {
		width: 50%;

		@media (max-width: 767px) {
			width: 100%;
		}
	}

	img {
		display: block;
		width: auto;
		
		@media (max-width: 767px) {
			max-height: 500px;
			object-fit: cover;
		}
	}

	.icon-img {
		margin-bottom: 25px;

		@media (max-width: 767px) {
			margin: 0 auto;
			margin-bottom: 25px;
			margin-top: 25px;
		}
	}

	.fh-carousel {
		width: 100%;
		.carousel-cell {
			width: 100%;
			img {
				display: block;
				width: 100%;
			}
		}
	}

	.flickity-button {
		background: $colorAccent2;
	}

	.flickity-prev-next-button {
		bottom: 15px;
		top: auto;
	}

	.flickity-prev-next-button.previous {
		left: auto;
		right: 75px;
	}
}

p, ul li {
	a {
		color: inherit;
		text-decoration: underline;
	}
}

p, p.regular-text {
	ul, ul li {
		list-style-type: disc;
		font-size: inherit;
		font-weight: inherit;
		line-height: inherit;

		font-family: 'Raleway', sans-serif;
		font-weight: 300;
		line-height: 1.34;
		font-size: 16px;
		color: $colorAccent1;

		@media screen and (max-width: 1000px) {
			font-size: 14px;
		}
	}
}

.spacer {
	height: 6vw;
}

.credit-wrap {
	position: relative;
}

.credits {
	font-family: 'Raleway', sans-serif;
	position: absolute;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.4);
	padding: 7px 10px;
	max-width: 100% !important;
	margin: 0 !important;

	p {
		max-width: 100% !important;
		padding: 0 !important;
		margin: 0 !important;
		color: #fff !important;
		font-size: 12px !important;
	}
}

.row.center,
.data.center {
	width: 100%;
	text-align: center;
	padding-top: 45px;

	p {
		margin: 0;
		padding: 0;
		max-width: 100%;
		width: 100%;
		text-align: center;

		padding-bottom: 20px;
		line-height: 1.35;
		padding-right: 0 !important;
	}

	.title {
		text-align: center;
	}
}

.charts-desktop {
	display: block;

	@media (max-width: 767px) {
		display: none;
	}
}

.charts-mobile {
	display: none;

	@media (max-width: 767px) {
		display: block;
	}
}

em {
	font-style: italic;
}