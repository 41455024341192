.bt {
	border: 1px solid $colorAccent2;
	background: $colorAccent2;
	display: inline-block;
	text-align: center;
	padding: 12px 34px;
	font-family: 'Raleway', sans-serif;
	color: $color1;
	transition: all 0.2s ease;
	text-transform: uppercase;
	cursor: pointer;

	p {
		transition: all 0.2s ease;
		font-weight: 600;
	}

	&:hover {
		background-color: $colorAccent3;
		color: $colorAccent1;
	}

	&.green {
		background: $colorAccent1;
		color: $colorAccent2;

		&:hover {
			background-color: $colorAccent3;
			color: $colorAccent1;
		}
	}
}

a.rounded-btn, .site-btn.rounded-btn, .lang.rounded-btn, .share.rounded-btn {
	font-family: 'Montserrat', sans-serif;
	border: 1px solid #fff;
	border-radius: 10px;
	padding: 6px 12px;
	text-align: center;
	line-height: 1;
	margin: 0;
	text-transform: uppercase;
	font-weight: 600;

	.title {
		margin: 0;
		padding: 0;
		font-family: 'Montserrat', sans-serif;
		text-transform: uppercase;
		font-weight: 600;
	}
}