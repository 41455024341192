body {
	background-color: $colorAccent3;
	color: $color4;
}

* { box-sizing: border-box; }

img {
	width: 100%;
	height: auto;
	display: inherit;
}

a {
	text-decoration: none;
}


.main-title .main-title-center, .main-title .main-title-center.title, .main-title-center {
	text-align: center;
	width: 100%;
	font-size: 2.75vw;
	letter-spacing: 3.5px;
	text-transform: uppercase;
	color: $colorAccent1;

	padding-right: 55px;

	@media screen and (max-width: 767px) {
		font-size: 26px;
		padding-right: 15px;

		br {
			display: none;
		}
	}
}

.main-title-sub {
	font-weight: 500;
	font-size: 18px;
	text-align: center;
}

.full-row {
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	padding-top: 4vw;

	@media (max-width: 767px) {
		flex-direction: column;
		align-items: center;
		justify-content: center;

		padding-right: 15px;
		padding-left: 15px;
	}

	.col-2 {
		width: 50%;
		padding: 0 4vw;

		@media (max-width: 767px) {
			width: 100%;
			padding: 0;
			padding-bottom: 25px;
		}
	}
}

.yellow {
	background: $colorAccent2;

	&.main-title .title {
		background-color: $colorAccent2;
	}

	.main-title .title {
		background-color: $colorAccent2;
	}
}

.green {
	background: $colorAccent1;
	color: $colorAccent2;

	&.main-title .title {
		background-color: $colorAccent1;
		color: $colorAccent2;
	}

	.main-title .title {
		background-color: $colorAccent1;
		color: $colorAccent2;
	}
}

::selection {
  background-color: $colorAccent2;
  color: $colorAccent1;
}

::-moz-selection {
  background-color: $colorAccent2;
  color: $colorAccent1;
}

.spacer {
	height: 3vw;
}